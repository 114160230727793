<template>
    <div>
        <div v-if="isRussian" class="marketo-form">
            <Typography class="title" variant="h3-display" as="h3">Рассылка Берлинского центра Карнеги</Typography>
            <Typography class="description" variant="body-display-small" as="p">{{
                formDescriptionRussian
            }}</Typography>
            <form id="mktoForm_1013"></form>
            <div id="confirmFormCTWEngage" style="display: none">
                <p>
                    Пожалуйста, проверьте электронную почту. Для подтверждения регистрации пройдите по ссылке в письме,
                    которое мы Вам отправили.
                </p>
            </div>
            <Typography class="description" variant="body-display-small" as="p"
                >{{ formDescriptionInfoRussian }}
            </Typography>
        </div>
        <div v-else class="marketo-form">
            <Typography class="title" variant="h3-display" as="h3">{{ $t('Subscribe Today') }}</Typography>
            <Typography class="description" variant="body-display-small" as="p">{{
                formDescriptionEnglish
            }}</Typography>
            <form id="mktoForm_1001"></form>
            <div id="confirmFormCTWEngage" style="display: none">
                <strong>Thank you!</strong>
                <p>Check your email for details on your request.</p>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    formContextData: {
        type: Object,
    },
    formDescriptionRussian: {
        type: String,
        default: 'Свежая аналитика экспертов Берлинского центра Карнеги — в вашей электронной почте.',
    },
    formDescriptionInfoRussian: {
        type: String,
        default:
            '<em>Не видите поля для адреса? <a href = "https://pages.carnegieendowment.org/Politika_signup-ru.html?updates=true" > Нажмите здесь, чтобы подписаться</a >.</em >',
    },
    formDescriptionEnglish: {
        type: String,
        default:
            'Subscribe to receive a monthly newsletter of the latest analysis from the Carnegie Russia and Eurasia Center, as well as announcements and invitations to Carnegie Russia and Eurasia’s public events.',
    },
});
const { locale } = useLocale();
const isRussian = computed(() => {
    return locale._value === 'ru';
});
function formCRECEn() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CMC SubscribePage Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ englishNewsletterAll: 'cmcEnglish' });
            form.addHiddenFields({ centerAffiliationAll: 'moscow' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formCRECRu() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1013, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CMC SubscribePage Mkto Ru' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ nonEnglishNewsletterAll: 'cmcRussian' });
            form.addHiddenFields({ centerAffiliationAll: 'moscow' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
onMounted(() => {
    if (locale._value === 'en') {
        formCRECEn();
    }
    if (locale._value === 'ru') {
        formCRECRu();
    }
});
</script>

<style lang="scss" scoped>
@import './././assets/styles/core/marketoForms';
</style>
